import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import SEO from "../components/SEO";
import HeroSection from "@src/components/partials/about/HeroSection";
import AboutSection from "@src/components/partials/about/AboutSection";
import BrandLoveSection from "@src/components/partials/about/BrandLoveSection";
import BeliefsSection from "@src/components/partials/about/BeliefsSection";
import TeamSection from "@src/components/partials/about/TeamSection";
import UpAwaySection from "@src/components/partials/about/UpAwaySection";
import { theme } from "@src/components/nessie-web";
import { graphql, useStaticQuery } from "gatsby";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const {
  colors: { dt_taro10 },
} = theme;

const BGColorToggle = styled.div<{ bgColor: boolean }>`
  background-color: ${(props) => (props.bgColor ? dt_taro10 : "transparent")};
  transition: background-color 0.8s ease;
`;

const AboutPage = () => {
  const { translate } = useContext(TranslationContext);

  const data = useStaticQuery(graphql`
    query {
      directus {
        page_about {
          page_description
          hero_title
          hero_signature
          hero_image {
            id
            filename_download
            filename_disk
          }
          about_title
          about_text
          about_image {
            id
            filename_download
            filename_disk
          }
          beliefs_title
          beliefs_text
          beliefs_image {
            id
            filename_download
            filename_disk
          }
          beliefs_items
          team_title
          team_item_image_1 {
            id
            filename_disk
          }
          team_item_username_1
          team_item_role_1
          team_item_text_1
          team_item_image_2 {
            id
            filename_disk
          }
          team_item_username_2
          team_item_role_2
          team_item_text_2
          team_item_image_3 {
            id
            filename_disk
          }
          team_item_username_3
          team_item_role_3
          team_item_text_3
        }
        page_careers {
          brand_love_title
          brand_love_text
          brand_love_images
          brand_love_tweets
          vision_mojo_image_url
          vision_title
          vision_text
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      page_about: {
        page_description,
        hero_image,
        about_image,
        beliefs_image,
        beliefs_items,
        team_item_image_1,
        team_item_username_1,
        team_item_image_2,
        team_item_username_2,
        team_item_image_3,
        team_item_username_3,
      },
      page_careers: { brand_love_images, brand_love_tweets, vision_mojo_image_url },
    },
  } = data;

  const [BGColor, setBGColor] = useState(false);
  const featuresRef = useRef(null);

  const intersectionObserverCallBack = (entries) => {
    const [entry] = entries;
    setBGColor(entry.isIntersecting);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(intersectionObserverCallBack, options);
    if (featuresRef.current) observer.observe(featuresRef.current);
    return () => {
      if (featuresRef.current) observer.unobserve(featuresRef.current);
    };
  }, [featuresRef]);

  const HeroSectionProps = {
    hero_title: translate("directus.page_about.hero_title").toString(),
    hero_signature: translate("directus.page_about.hero_signature").toString(),
    hero_image,
  };

  const AboutSectionProps = {
    about_title: translate("directus.page_about.about_title").toString(),
    about_text: translate("directus.page_about.about_text").toString(),
    about_image,
  };

  const BrandLoveSectionProps = {
    brand_love_title: translate("directus.page_careers.brand_love_title").toString(),
    brand_love_text: translate("directus.page_careers.brand_love_text").toString(),
    brand_love_cta_text: "directus.page_careers.brand_love_cta_text",
    brand_love_images: brand_love_images.map((_, index) => ({
      ..._,
      alt: translate(`directus.page_careers.brand_love_images.alt_${index + 1}`).toString(),
    })),
    brand_love_tweets: brand_love_tweets.map((_, index) => ({
      ..._,
      text: translate(`directus.page_careers.brand_love_tweets.text_${index + 1}`).toString(),
    })),
    BGColor,
    three_liner: true,
    translate: false,
  };

  const BeliefsSectionProps = {
    beliefs_title: translate("directus.page_about.beliefs_title").toString(),
    beliefs_text: translate("directus.page_about.beliefs_text").toString(),
    beliefs_image,
    beliefs_items: beliefs_items.map((_, index) => ({
      title: translate(`directus.page_about.beliefs_items.title_${index + 1}`).toString(),
      text: translate(`directus.page_about.beliefs_items.text_${index + 1}`).toString(),
    })),
  };

  const TeamSectionProps = {
    team_title: translate("directus.page_about.team_title").toString(),
    team_item_image_1,
    team_item_username_1,
    team_item_role_1: translate("directus.page_about.team_item_role_1").toString(),
    team_item_text_1: translate("directus.page_about.team_item_text_1").toString(),
    team_item_image_2,
    team_item_username_2,
    team_item_role_2: translate("directus.page_about.team_item_role_2").toString(),
    team_item_text_2: translate("directus.page_about.team_item_text_2").toString(),
    team_item_image_3,
    team_item_username_3,
    team_item_role_3: translate("directus.page_about.team_item_role_3").toString(),
    team_item_text_3: translate("directus.page_about.team_item_text_3").toString(),
    title_align_center: false,
  };

  const UpAwaySectionProps = {
    vision_mojo_image_url,
    vision_title: translate("directus.page_careers.vision_title").toString(),
    vision_text: translate("directus.page_careers.vision_text").toString(),
  };

  return (
    <>
      <SEO title="page_titles.about" description="directus.page_about.page_description" />
      <HeroSection {...HeroSectionProps} />
      <AboutSection {...AboutSectionProps} />
      <BGColorToggle bgColor={BGColor}>
        <BrandLoveSection {...BrandLoveSectionProps} />
        <div ref={featuresRef}>
          <BeliefsSection {...BeliefsSectionProps} />
        </div>
        <TeamSection {...TeamSectionProps} />
      </BGColorToggle>
      <UpAwaySection {...UpAwaySectionProps} />
    </>
  );
};
export default AboutPage;
